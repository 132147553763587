import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Col, Row } from 'antd';
import Link from '../Link';
import Logo, { LOGO_DARK_PINK_DOT } from '../Logo';

class AccountOnboardingHeader extends PureComponent {
  render = () => (
    <Row
      align="middle"
      className="verify-create-header"
      justify={this.props.hideLogin ? 'start' : 'space-between'}
      type="flex"
    >
      <Col>
        <Logo logo={LOGO_DARK_PINK_DOT} width={60} />
      </Col>
      {!this.props.hideLogin && (
        <Col>
          Already have an account? <Link to="/login">Log in</Link>
        </Col>
      )}
    </Row>
  );
}

AccountOnboardingHeader.propTypes = {
  hideLogin: PropTypes.bool,
};

export default AccountOnboardingHeader;
