import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Col, Row } from 'antd';

const VERIFY = 'verify';
const CREATE = 'create';
const LOGIN = 'login';

class AccountOnboardingGreeting extends PureComponent {
  getHeader = () => {
    switch (this.props.case) {
      case 'login': {
        if (this.props.isComingFromResetPassword) {
          return 'Password Reset!';
        }

        if (this.props.isComingFromAccountOnboarding) {
          return 'Account Created!';
        }

        return 'Welcome back!';
      }

      default:
        return 'Welcome!';
    }
  };

  getImageAlt = () => {
    switch (this.props.case) {
      case 'create':
      case 'verify':
        return 'enthusiastic co-workers';
      default:
        return 'cheerful office buildings';
    }
  };

  getImageSrc = () => {
    switch (this.props.case) {
      case 'create':
      case 'verify':
        return '/images/queue/high-five.svg';
      default:
        return '/images/queue/office-buildings.svg';
    }
  };

  getText = () => {
    switch (this.props.case) {
      case 'login': {
        if (this.props.isComingFromAccountOnboarding) {
          return (
            <div>
              You're all ready to access Solv! <br />
              Come on in!
            </div>
          );
        }

        return '';
      }

      default:
        return "Let's set up your account so you can access Solv";
    }
  };

  render = () => {
    return (
        <>
          <Col className="account-onboarding-greeting-container" xs={24} sm={12}>
          <Row className="account-onboarding-greeting-header">{this.getHeader()}</Row>
          <Row className="account-onboarding-greeting-text">{this.getText()}</Row>
          <Row className="account-onboarding-greeting-image">
            <img alt={this.getImageAlt()} src={this.getImageSrc()}/>
          </Row>
        </Col>
          </>
    );
  };
}

AccountOnboardingGreeting.propTypes = {
  case: PropTypes.string,
  isComingFromAccountOnboarding: PropTypes.bool,
  isComingFromResetPassword: PropTypes.bool,
};

export { AccountOnboardingGreeting as default, VERIFY, CREATE, LOGIN };
