import PropTypes from 'prop-types';
import React from 'react';
import Link from '../../components/Link';
import s from './Logo.module.scss';
import withStyles from '../../core/isomorphic-style-loader/withStyles';

export const LOGO_WHITE_YELLOW_DOT = 'beta_white_with_yellow_dot.png';
export const LOGO_WHITE_PINK_DOT = 'beta_white_with_pink_dot.png';
export const LOGO_DARK_PINK_DOT = 'Solv-Logo-DP-319x120.png';

const Logo = props => {
  const getImageUrl = () => `/images/logo/${props.logo || LOGO_WHITE_YELLOW_DOT}`;

  return (
    <div className={props.className}>
      <Link to="/">
        <img src={getImageUrl()} width={props.width || 105} alt="Solv" className={s.logo} />
      </Link>
      <span />
    </div>
  );
};

Logo.propTypes = {
  logo: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.number,
};

export default withStyles(s)(Logo);
